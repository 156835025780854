@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lilita+One&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.contactHeader {
  padding: 4px 10rem 4px 10rem;
  animation: Move-ltr 15000ms linear infinite;
  color: #181616;
}

@keyframes Move-ltr {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
